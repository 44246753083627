import { AboutOneData } from "@/data";
import Link from "@/components/link";
import React from "react";
import { FadeInImage, FadeInRegular } from "@/components/gsap/gsapComponents";

const AboutOne = () => {
  const { title, tagLine, content, button } = AboutOneData;
  return (
    <section className="about_area section_padding" id="aboutArea">
      <div className="container">
        <div className="row">
          <FadeInImage>
            <div className="col-md-6">
              <div className="about_image about-image__updated"></div>
            </div>
          </FadeInImage>
          <div className="col-md-6">
            <div className="about_details">
              <div className="hero-title-with-shape">
                <h4 className="heading_with_border">{tagLine}</h4>
                <h1>{title}</h1>
              </div>
              <p>{content}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="about_details">
              <div className="hero-title-with-shape">
                <h1>What We Offer</h1>
              </div>
              <p> Some of our services include:</p>
              <ul>
                <li>Green Cleaning</li>
                <li>Medical cleaning</li>
                <li>Carpet cleaning</li>
                <li>Power washing</li>
                <li>Biohazard cleanup</li>
              </ul>
            </div>
          </div>
          <FadeInImage>
            <div className="col-md-6">
              <div className="about_image2 about-image__updated"></div>
            </div>
          </FadeInImage>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <FadeInImage>
            <div className="col-md-6">
              <div className="about_image3 about-image__updated"></div>
            </div>
          </FadeInImage>
          <div className="col-md-6">
            <div className="about_details">
              <div className="hero-title-with-shape">
                <h1>Your Business is Ours</h1>
              </div>
              <p>
                We promise quality, reliable cleaning service every time so our loyal customers feel
                special. We work hard to be the best. Our products are high-quality and safe. And we
                focus on the details, so you have a clean, orderly office every time.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutOne;
