import React, { useRef } from "react";
import * as emailjs from "emailjs-com";

const ContactOne = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    console.log(e);

    emailjs
      .sendForm("service_94rqbbl", "template_lbbhnh4", form.current, "user_T32tRiCHFG5TLgFzAhMQc")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <section className="contact_form_area section_padding container">
      {/* <div className="contact_form_width">
        <h1 className="contact_map_title text-center">
          Your time is valuable, and we will make sure to get back to you as soon as we can.
        </h1>
        <div id="map">
          <iframe
            title="template google map"
            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d15583.475415418294!2d130.84450151053562!3d-12.458453559798478!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sau!4v1634870934365!5m2!1sen!2sau"
            allowFullScreen></iframe>
        </div>
      </div> */}
      <div className="contact_form_width contact-right" id="contactQuote">
        <div className="hero-title-with-shape">
          <h4 className="heading_with_border">Get a free quote</h4>
          <h1>To request a cleaning estimate, please fill out the form below</h1>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input
            className="half_width input_m_right"
            type="text"
            name="name"
            placeholder="Your name"
          />
          <input className="half_width" type="EMAIL" name="email" placeholder="Email address" />
          <input type="tel" name="phone" placeholder="Phone number" />
          <input
            className="half_width input_m_right"
            type="text"
            name="fcleaning"
            placeholder="Frequency of Cleaning"
          />
          <input
            className="half_width"
            type="text"
            name="squarearea"
            placeholder="Approximate Square Footage to be cleaned"
          />
          <textarea
            name="text"
            rows="3"
            wrap="soft"
            name="message"
            style={{ border: "none", outline: "none", padding: "20px", color: "#87868a", width:"100%" }}
            placeholder="Tell us more about your cleaning needs so we can give you a more accurate estimate.How is the space used? Does it have any special cleaning needs?"></textarea>
          <button className="btn-yellow" value="SUBMIT NOW">
            SUBMIT NOW
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactOne;
